<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">
        {{ $t('common.fill_all') }}
      </p>
    </header>

    <section class="modal-card-body">
      <a-card
        :editable="false"
      >
        <a-input
          field="name"
          :required="true"
          :manual-doc="docPath"
          :label="$t('failures.ref.name')"
          type="textarea"
        />

        <a-input
          field="resolution"
          :required="true"
          :manual-doc="docPath"
          :label="$t('failures.resolutionForm.type')"
          type="textarea"
        />
      </a-card>
    </section>
    <footer class="modal-card-foot">
      <button
        v-if="hasRequiredFields"
        class="button is-success"
        type="button"
        @click="close"
      >
        <span>{{ $t('common.ok') }}</span>
      </button>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    hasRequiredFields() {
      return Boolean(this.doc.name) && Boolean(this.doc.resolution) // ! condition to be met
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
